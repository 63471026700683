import React, { useEffect } from "react";
import "./token-expired-page.scss";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import logo from "../../assets/icons/logo.svg";
import { findByDomainCustomization } from "../../crud/customize";
import { setConfigsByDomain } from "../../redux/actions";

export const TokenExpiredPage = () => {
  const dispatch = useDispatch();
  const logo = useSelector((state) => state.profile.logo);

  useEffect(() => {
    findByDomainCustomization(
      new URL(document.URL).hostname
      // 'development.spadmin.akrotonx.com'
    ).then((res) => {
      dispatch(setConfigsByDomain(res.data));
    });
  }, []);

  return (
    <div className="token-expired-page">
      <div className="main-block">
        <div className="logo">
          <img src={logo} alt="logo" />
        </div>
        <div className="form">
          <div className="form__head flex-sb">
            <h4>Time expired</h4>
            {/* <h4>Time for password recovery has expired</h4> */}
          </div>

          <div className="form__bottom-text">
            <Link to="/login" className="form__link">
              <span className="form__arrow">{"<"}</span>
              <span>Back to Login</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
