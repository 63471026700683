import React, { useState, useEffect } from "react";
import { getImage } from "../../crud/files";

export const ImageComponent = ({ src, alt, forceUpdate }) => {
  const [url, setUrl] = useState();

  useEffect(() => {
    let isMounted = true;

    if (src) {
      getImage(src)
        .then((res) => {
          if (isMounted) {
            if (res.data.type === "local") {
              const tryUrl = `https://${process.env.REACT_APP_DO_SPACE_NAME}.${process.env.REACT_APP_DO_SPACES_ENDPOINT}/${res.data.path}`;
              setUrl(tryUrl);
            } else if (res.data.type === "remote") {
              setUrl(res.data.path);
            }
          }
        })
        .catch((error) => {
          if (isMounted) {
            console.error("Error en getImage:", error);
          }
        });
    }

    return () => {
      isMounted = false;
    };
  }, [src, forceUpdate]);

  return url ? <img src={url} alt={alt || ""} /> : <></>;
};
